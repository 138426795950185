import { ref } from 'vue'
import axios from 'axios'
import configs from "../../controllers/configs"

const getLocalReportPeriods = () => {
    const months = [] as string[]
    const lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)
    let year = lastMonth.getFullYear()
    let month = lastMonth.getMonth() + 1 // JavaScript months are 0-based

    for (let i = 0; i < 12; i++) {
        if (month == 0) {
            month = 12
            year--
        }

        // Format month with leading zero if needed
        const formattedMonth = month.toString() //.padStart(2, '0')
        months.push(`${year}-${formattedMonth}`)
        
        month--
    }

    // months.reverse()
    return months
}

const reportPeriods = ref<string[]>(getLocalReportPeriods())

export const initReportPeriods = async () => {
    try{
        const res = await axios.get(`${configs.urlPrefix}/dataforms/report-periods`)
        console.log("init reportPeriods res", res)
        const data = res.data.payload
        console.log("init reportPeriods", data)
        reportPeriods.value = data
        localStorage.setItem('reportPeriods', JSON.stringify(data))
    }catch(e){
        console.log("init reportPeriods err", e)
    }
}

export const getReportPeriods = () => {
    return reportPeriods.value
}