import getRole from '../auth/getRole'
import {useUserInfoStore} from '../stores/userInfoStore'
import {fetchBatchStatus} from '../controllers/dataform/fetchBatchStatus'
import { getReportPeriods } from "../utils/dataform/reportPeriods"

export const dataformPassed = async ():Promise<"已审核" | "未通过" | "未提交" | "未审核" | "zz2"> => {
    const userRole = getRole()
    if(!["酒店","酒店试用"].includes(userRole)){
        return "已审核"
    }
    const hotel = useUserInfoStore().info.hotel
    if(!hotel){
        return "未提交"
    }
    console.log("dataformPassed useUserInfoStore().info", useUserInfoStore().info)
    console.log("dataformPassed hotel", hotel)
    const dataformTitle = "酒店月度数据填报"
    const hotelId = hotel.id
    const dataformPeriods = getReportPeriods()
    const cacheKey = `${hotelId}-${dataformTitle}-${dataformPeriods.join('-')}`
    // const localResult = localStorage.getItem(cacheKey)
    // console.log("localResult", localResult)
    // if(localResult == "已审核"){
    //     return localResult
    // }
    const batchStatus = await fetchBatchStatus(dataformTitle, hotelId, dataformPeriods)
    console.log("batchStatus", batchStatus)
    if( 
        !!Object.values(batchStatus).find(
            status => status == "已审核（必填数据）" 
            || status == "已审核（全部数据）"
            || status == "已审核")){
        localStorage.setItem(cacheKey, "已审核")
        return "已审核"
    }
    if( !!Object.values(batchStatus).find(status => status == "未通过")){
        return "未通过"
    }
    if( !!Object.values(batchStatus).find(status => status == "未审核")){
        return "未审核"
    }
    return "未提交"
}
