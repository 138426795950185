import axios from "axios"
import configs from "../configs"

type FetchBatchStatusResult = {
    [key:string]:string
}

const cache = {}

export const fetchBatchStatus = async (
    title: string,
    hotelId: number,
    dataformPeriods:string[],
):Promise<FetchBatchStatusResult> => {
    try{
        const cacheKey = `${hotelId}-${title}-${dataformPeriods.join('-')}`
        // if(cache[cacheKey]){
        //     return cache[cacheKey]
        // }

        const res = await axios.post(configs.urlPrefix + '/dataforms/batch-status', {
            dataform_periods: dataformPeriods,
            hotel_id: hotelId,
            title: title
        })
        
        if (res.data.code != 0){
            return {} as FetchBatchStatusResult
        }

        console.log('fetchBatchStatus payload', res.data.payload)
        cache[cacheKey] = res.data.payload
        return res.data.payload as FetchBatchStatusResult
    }catch(e){
        console.log('getDataforms err', e)
        return {} as FetchBatchStatusResult
    }
}
