<template>
  <LocaleProvider :locale="zh_CN">
      <NuxtPage />
  </LocaleProvider>
    <Modal style="height:80vh"/>
    <!-- <PWAPromptWrapper/> -->
    <!-- <Watermark v-if="route.path != '/login'" style="top:50px" :text="getWatermarkText()" /> -->
</template>

<script setup lang="ts">
import { LocaleProvider } from 'ant-design-vue';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn'; // import moment locale


moment.locale('zh-cn'); // set moment locale globally
import version from './version'
import tscroll from './utils/tscroll'
import axios from 'axios'
import {useIsMobileStore} from '~/stores/isMobileStore'

import {useUserInfoStore} from '@/stores/userInfoStore'
import { axios_emitter } from '@/init/initaxios'
import { modal } from '@tian-libs/modal'
import { initReportPeriods } from '@/utils/dataform/reportPeriods'
import {ref} from 'vue'
import {useRoute} from 'vue-router'



const nav = ref('')

const route = useRoute()

const userInfoStore = useUserInfoStore()
const getWatermarkText = () => {
    if(userInfoStore.info){
        const phoneLast4 = userInfoStore.info.phone_number ? userInfoStore.info.phone_number.slice(-4) : ''
        return userInfoStore.info.name + ' ' + phoneLast4
    }
    else{
        return ''
    }
}

axios_emitter.on('unauthorized', () => {
    console.log('unauthorized')
    alert('登录已过期，请重新登录')
    window.location.href = '/login'
})

const init = async ()=>{
    await initReportPeriods()
    const jwtFromCookie = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1]
    console.log('jwtFromCookie', jwtFromCookie)
    if(jwtFromCookie){
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwtFromCookie}`
    }

    console.log('version', version)
    try{

        const isMobileStore = useIsMobileStore()
        
        if(process.client){
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                isMobileStore.value = true
            }
            else{
                isMobileStore.value = false
            }
        }

        if(process.env.NODE_ENV == 'production'){
            // console.log = () => {}
        }
        
        if(process.client){
            nav.value = navigator.userAgent
        }
    }catch(e){
        console.log('mount err', e)
    }

    if(process.client){
        window.addEventListener('scroll', () => {
            tscroll.value = document.documentElement.scrollTop
        })
    }
    // afterLogin()
}
await init()
</script>

<style scoped>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.version_display{
    color: #636363;
    position: absolute;
    right: 10px;
}
</style>

<style>

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input{
    border-radius: 15px;
    border-color: black;
    background-color: rgba(237, 246, 250, 1);
}
.anticon{
    color:black
}

.grayoutlesshover {
    filter: grayscale(100%);
    transition: filter 0.1s ease-in-out;
}
.grayoutlesshover:hover {
    filter: grayscale(0%);
}
</style>

